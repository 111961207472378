.buttonContentHidden {
  visibility: hidden;
}

.buttonLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: block;
  
  width: 20px;
  height: 20px;
}

.errorListItem:not(:last-child) {
  margin-bottom: 10px;
}

.errorText {
  font-size: 12px;
  color: #ff4d4f;
  margin-bottom: 0;
}

