.page {
  padding-top: 17px;
  padding-bottom: 48px;
}

.pageBody {
  width: 80%;
  margin: 0 auto;
}

.links {
  align-items: center;

  display: flex;

  margin-bottom: 50px;
}

.link:not(:last-child) {
  display: block;

  margin-right: 20px;
}

.pageHeader {
  justify-content: space-between;
  align-items: center;

  display: flex;

  margin-bottom: 48px;
}

.pageTitle {
  margin-bottom: 0;
  
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 59px;
  color: #262626;
}

.buttons {
  gap: 0 8px;

  display: flex;

  margin-top: 48px;
}

.button {
  position: relative;

  border-color: #494580;
  border-radius: 4px;
  padding: 9px 11px;

  background-color: #494580;
}

.button:hover {
  background-color: #5b55b0;
}

button:disabled {
  cursor: default;
}

.buttonContent {
  color: #fff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.buttonContentHidden {
  visibility: hidden;
}

.buttonLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: block;
  
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 992px) {
  .pageTitle {
    font-size: 32px;
    line-height: 40px;
  }
}

.errorListItem:not(:last-child) {
  margin-bottom: 10px;
}

.errorText {
  font-size: 12px;
  color: #ff4d4f;
  margin-bottom: 0;
}