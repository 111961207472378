.header {
  justify-content: space-between;

  display: flex;

  width: 100%;
}

.left {
  align-items: center;

  display: flex;

  width: 100%;

  margin-right: 96px;
}

.logo {
  font-weight: 700;
  font-size: 24px;
  color: #000;
  margin-right: 15px;
}

.right {
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
}

.nav {
  font-weight: 700;
  font-size: 24px;
  color: #000;
  padding-right: 1.5em;
}

.navVisuallyHidden {
    visibility: hidden;
}
