.pageContentLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.empty {
  border-radius: 8px;
  padding: 20px 0;

  background: #F5FAFE;

  text-align: center;
}

.emptyText {
  margin: 0;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.searchInputContainer {
  margin-bottom: 20px;
}

.searchInput {
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  padding: 0px 18px;
  width: 100%;

  background: #FFFFFF;
}

.tableContainer {
  margin-bottom: 20px;
}


.table {}

.table :global(.ant-table-measure-row) {
  display: table-row !important;
}

.table :global(.ant-table-thead .ant-table-column-title),
.table :global(.ant-table-thead .ant-table-cell) {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  text-transform: uppercase;
}

.table :global(.ant-table-thead .ant-table-cell) {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 10px !important;
  border-bottom: 1px solid #C7E6FF !important; 
  height: auto;
}

.table :global(.ant-table-thead tr:first-child .ant-table-cell) {
  border-top: 1px solid #C7E6FF !important; 
}

.table :global(.ant-table-thead .ant-table-cell .ant-table-column-sorters) {
  justify-content: flex-start;
}

.table :global(.ant-table-thead .ant-table-cell .ant-table-column-title) {
  margin-right: 5px;
  flex: 0;
}

.table :global(.ant-table-tbody .ant-table-cell) {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 10px !important;
  padding-right: 0 !important;
  border-bottom: 1px solid #C7E6FF !important;
}

.tableCell {
  
}

.tableCellColumn.tableCellColumn {
  white-space: normal;
}

.tableCellName {
  display: block;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
}

.tableCellText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.tableCellTextBold {
  color: green;
  font-weight: bold;
}

.topCellBorder {
  border-top: 1px solid #C7E6FF !important;
}

.rightCellBorder {
  border-right: 1px solid #C7E6FF !important;
}

.bottomCellBorder {
  border-bottom: 1px solid #C7E6FF !important;
}

.leftCellBorder {
  border-left: 1px solid #C7E6FF !important;
}