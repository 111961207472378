.container {
    max-width: 960px;
    margin: auto;
    padding: 1rem;
    overflow: hidden;
}

.largeContainer {
    max-width: 1280px;
}

.semiLargeContainer {
    max-width: 987px;
}

@media (max-width: 992px) {
    .container {
        padding: 0;
    }

    .container {
        margin: inherit;
        padding: 0;
        overflow: hidden;
    }

}