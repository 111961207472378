input {
  height: 34px;
}

button:disabled {
  cursor: default !important;
}

.page {
  font-family: "Arial", serif;
}

.proposal_settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 48px;
}

.proposal_settings_menu_item {
  display: flex;
}

.title {
  display: inline-block;
  color: #000;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 50px;
}

.products_title {
  display: flex;
  padding-bottom: .5em;
}

.products_title div:not(:last-child) {
  margin-right: 10px;
}

.addProductOption {
  border-top: 2px dashed rgb(145, 138, 255);
  margin-top: 8px;
  padding-bottom: 19px;
  padding-top: 13px;
}

.products_title div:nth-child(1) {
  width: 200px;
  flex-grow: 1;
}

.products_title div:nth-child(2) {
  width: 210px;
}

.products_title div:nth-child(3) {
  width: 78px;
}

.products_title div:nth-child(4) {
  width: 120px;
}

.form {
  display: flex;
  padding: 0 20px;
}

.bottom_inner {
  position: relative;
}

.bottom_inner hr {
  position: absolute;
  top: 30px;
  left: 11px;
  width: 97%;
}

.form_header {
  align-items: center;
  
  display: flex;

  margin-bottom: 14px;
}

.form_title {
  display: flex;

  font-size: 14px;
  color: #333;
  font-weight: 700;
}

.form_title_container {
  display: flex;
}

.form_title_link {
  cursor: pointer;
  color: #918aff;
}

.settings_icon {
  margin-left: 8px;
}

.form_button {
  display: flex;
  align-items: center;
} 

.form_button:not(:last-child) {
  margin-bottom: 16px;
}

.form_title_tooltip {
  margin-left: 8px;
}

.tooltip_content {
  padding: 16px;

  color: #333;
  font-weight: 400;
  font-size: 14px;
}

textarea {
  height: 100px;
  padding: 5px;
}

.upload_btn {
  border: 1px solid #7b7b7b;
  width: 326px;
  padding-right: 0;
}

.upload_btn:hover {
  border-color: #40a9ff !important;
}

.cross_img {
  position: absolute;
  z-index: 4;
  width: 16px;
  cursor: pointer;
  right: 9px;
  top: 9px;
  opacity: .2;
}

.proposal_contacts {
  display: none;
}

.proposal_contacts__active {
  display: block;
  
  margin-right: 32px;
  width: 45%;
  overflow-y: auto;
  max-height: calc(100vh - 187px);
}

/* bottom */
.bottom {
  overflow-y: auto;
  max-height: calc(100vh - 187px);
  width: 100%;
}

.bottom__small {
  width: 55%;
}

.bottom_header {
  font-size: 16px;
  display: grid;
  grid-template-columns: 47fr 30fr 10% 8% 10%;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 1.5em;
}

.bottom_body {
}

.product {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.product:first-child {
  margin-top: 1.5em;
}

.product:not(:first-child) > .input_bottom > div {
  padding-top: 0;
}

.input_bottom {
  height: 100%;
  position: relative;
}

.input_bottom:not(:last-child) {
  margin-right: 10px;
}

.search_item {
  padding: 7px 5px 7px 9px !important;
  height: 42px;
}

.search_item_highlighted {
  background-color: #f2f2f2 !important;
}

.search_result {
  display: flex;
  padding-top: 5px;
}

.search_input_borderless {
  border-bottom: 0px solid !important;
}

.search_result h6:nth-child(2) {
  color: rgb(113, 118, 255);
  border-width: 2px;
  border-bottom-style: dashed;
  border-bottom-color: initial;
}

.input_bottom input {
  height: 34px !important;
}

.input_bottom:nth-child(1) {
  width: 200px;
  flex-grow: 1;
}

.input_bottom:nth-child(2) {
  width: 210px;
}

.input_bottom:nth-child(3) {
  width: 78px;
}

.input_bottom:nth-child(3) input {
  border: 1px solid #747474;
}

.input_bottom:nth-child(4) {
  width: 120px;
}

.input_bottom > div {
  padding-top: .5em;
  position: relative;
}

.search_input {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #4f4f4f;
}

.search_input_autocomplete {
  align-items: center;
  background: #fff;
  width: 100%;
}

.input_select:focus {
  border: 1px solid #1890ff;
  box-shadow: 0px 1px 6px 0px #1890ff;
}

.search_input_autocompelete_debounce {
  border-bottom: 1px solid #4f4f4f;
}
.search_input_autocompelete_debounce:focus {
  border: 1px solid #40a9ff;
}


.input_bottom input,
.input_bottom select {
  width: 100%;
  padding: 5px 10px;
  border-color: #747474;
  font-size: 17px;
}

.input_bottom select {
  border: 1px solid #4f4f4f;
}

.input_bottom select {
  font-size: 13px;
  height: 34px;
  padding: 0;
}

.input_bottom input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}

.input_bottom:nth-child(1) input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  box-shadow: none;
}

.cross {
  position: absolute;
  z-index: 4;
  bottom: 0;
  width: 15px;
  height: 30px;
  cursor: pointer;
  right: 10px;
  opacity: 0.2;
}

.product:first-child > .input_bottom > div > .cross_img {
  top: 1em;
}

.input_bottom.delete {
  display: flex;
  align-items: baseline;

  justify-content: space-between;
}

.delete_button_wrap {
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

.delete_button {
  height: 25px;
  width: 21px;
  border: 1px solid rgb(107 107 107);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #efefef;
  cursor: pointer;
  border-radius: 3px;
}

.delete_button:hover {
  background: #e4e4e4;
  border-color: #565656;
}

.delete_button span {
  width: 55%;
  height: 5px;
  background-color: #000;
}

.buttons {
  align-items: center;

  display: flex;

  margin-bottom: 25px;
}

.add_button {
  border: 1px solid #8f8f8f;

  padding: 3px 10px;
  width: 185px;
  background: #f7f7f7;
  border-radius: 3px;
  cursor: pointer;
  color: #333;
  font-family: "Arial Обычный", Arial;
  transition: 0.1s linear;
  font-size: 13px;
}

.add_button button {
  width: 100%;
}

.add_button_inner {
  width: 100%;
  display: flex;
  align-items: center;
}

.add_button_inner img {
  margin-right: 15px;
}

.marketLink {
  display: block;

  margin-left: 8px;

  background: #494580;
  border-radius: 4px;
  padding: 9px 11px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.marketLink:visited,
.marketLink:active,
.marketLink:focus {
  color: #ffffff;
}

.bottom_footer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.quantitySelect {
  background: white;
  width: 120px;
}

.saveTemplateButton {
  display: flex;
  background: #fff;
  border: 2px solid #000;
  padding: 12px 25px;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  transition: 0.1s linear;
  margin-left: 1em;
}

.saveTemplateButton:hover {
  background: rgba(255, 255, 255, .5);
}

.footer_text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  color: #333;
}

.footer_text a {
  cursor: pointer;
  color: #918aff;
}

/* about */

/* mobile */
.top_right_mobile {
  padding-left: 0;
  display: none;
}

label[for="file"] {
  border: 1px solid gray;
  width: 326px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  padding-left: 3px;
  height: 30px;
  cursor: pointer;
  font-size: 17px;
  margin-bottom: 0;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.autocomplete_list {
  background: #fff;
  border-top: none;
  max-height: 300px;
  overflow-y: scroll;
  border-right: 1px solid #40a9ff;
  border-left: 1px solid #40a9ff;
  border-bottom: 1px solid #40a9ff;
}

.autocomplete_list div {
  cursor: pointer;
  background-color: #fff;
  font-size: 14px;
}

.autocomplete_list div:hover {
  background-color: #f2f2f2;
}

.autocomplete_list span {
  font-family: Arial Обычный, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.search_block_container_1 {
  padding: 9px;
}

.add_new_product {
  width: 180px;
  cursor: pointer;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border-width: 59px;
  line-height: 12px;
  border-bottom: 2px dashed #918aff;
  border-top: none;
  color: #918aff;
  background: inherit !important;
}

.importing_loader {
  margin-left: 8px;
}

@media (max-width: 1279px) {
  .form {
    flex-direction: column;
  }

  .bottom {
    width: auto;
    max-height: auto;
  }

  .proposal_contacts__active {
    margin-right: 0;
    width: 100%;
    max-height: 100%;
  }

  .top_right_mobile {
    display: block;
  }

  .search_result {
    display: block;
  }

  .saveTemplateButton {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-left: 0;
    margin-top: 1em;
  }

  .upload_btn {
    border: 1px solid #7b7b7b;
    width: 100%;
    padding-right: 0;
  }

  .products_title div {
    width: auto;
    flex-shrink: 0;
  }

  .product:first-child > .input_bottom > div > .cross_img {
    top: 40%;
  }

  .bottom_body {
    overflow-x: auto;
  }

  .footer_text {
    padding-left: 0;
    padding-top: 2em;
  }

  .add_button button {
    width: 100%;
  }

  .add_button {
    display: flex;
    justify-content: center;
  }

  .add_button_inner {
    justify-content: center;
  }

  .add_button {
    width: 100%;
    height: 40px;
  }

  .bottom_footer {
    display: initial;
  }

  .input_bottom > input, .input_bottom > div > input {
    width: 100%;
  }

  .input_bottom {
    width: 20%;
    flex-shrink: 0;
  }

  .upload_btn {
    width: 100%;
    padding-right: 4px;
    border: 1px solid #7b7b7b;
    position: initial;
  }

  .bottom_inner, .input_bottom {
    position: initial;
  }

  .page {
    padding-top: 80px;
  }

  .title {
    display: flex;
    justify-content: center;
  }

  label[for="file"] {
    min-width: 300px;
    width: 100%;
  }
}

@media screen and (max-width: 424px) {
  .hide_on_mobile {
    display: none;
  }
}