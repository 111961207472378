.form {
  display: flex;
}

.inputGroups {
  width: 100%;
  max-width: 455px;
}

.inputGroupItems {
  display: flex;
}

.inputGroupItem {
  display: flex;
  flex-wrap: wrap;
}

.inputGroupItem:not(:last-child) {
  margin-bottom: 20px;
}

.inputGroupFlex {
  width: calc(50% - 10px);
}

.inputGroupFlex:not(:last-child) {
  margin-right: 20px;
}

.inputDescription {
  display: block;

  margin-bottom: 9px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.formItem {
  width: 100%;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.formItem :global(.ant-select-selector.ant-select-selector),
.formItem :global(.ant-input.ant-input) {
  align-items: center;

  height: 50px !important;
  padding-left: 20px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.formItem :global(.ant-input.ant-input)::placeholder {
  color: #757575;
}

.formItemTextarea :global(.ant-input.ant-input) {
  padding-top: 14px;
  padding-left: 16px;
  height: 148px !important;
  
  resize: none;
}

.imagesList {
  flex-wrap: wrap;
  gap: 24px 24px;

  display: flex;

  margin-top: 20px;
}

.image {
  position: relative;
}

.imageRemove {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);

  align-items: center;
  justify-content: center;

  display: flex;

  width: 32px;
  height: 32px;
  border-radius: 50%;

  background: #494580;
}

.formItemUpload {
  margin-bottom: 9px;
}

@media screen and (max-width: 992px) {
  .inputGroupFlex {
    width: 100%;
  }

  .inputGroupFlex:first-child {
    margin-bottom: 20px;
  }

  .inputGroupFlex:not(:last-child) {
    margin-right: 0;
  }
}