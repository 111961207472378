tr, th, td {
    font-size: 13px !important;
}

.fade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 100;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.4s ease;
    visibility: hidden;
}

.centred {
    text-align: center;
}

.w100 {
    width: 100%;
}


.fade.active {
    opacity: 0.4;
    visibility: visible;
}

.cart {
    position: fixed;
    top: 40%;
    left: 50%;
    z-index: 101;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    max-width: 1100px;
    max-height: 700px;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    overflow-y: scroll;
    background-color: #fff;
    transition: 0.4s ease;
    font-family: "Arial Полужирный", "Arial Обычный", "Arial", sans-serif;
}

.cart.active {
    opacity: 1;
    visibility: visible;
    top: 50%;
}

.cart .inner td,
.cart .inner th {
    white-space: normal;
}

.inner {
    padding: 16px 32px;
}

.top {
    border-bottom: 1px solid #dee2e6;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
}

.left {
    display: flex;
    align-items: center;
}

.title {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding-left: 10px;
    color: #737373;
}

.cross {
    cursor: pointer;
    padding: 10px;
}

.cross img {
    width: 10px;
    height: 10px;
    opacity: 0.5;

    transition: opacity 0.4s ease;
}

.cross:hover img {
    opacity: 1;
}

.footer {
    padding-top: 20px;
    font-weight: 400;
    font-size: 13px;
    color: #737373;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info {
    padding-right: 15px;
}

/* buttons */
.button {
    min-width: 180px;
    height: 38px;
    background-color: #494580;
    border-color: #494580;
    color: #fff;
    padding: 10px 22px;
    border-radius: 0.2em;
    font-size: 16px;
    transition: background-color 0.4s ease;
    cursor: pointer;
}

.button:hover {
    background-color: #5b55b0;
}

.button_save {
    margin-right: 6px;
    height: 38px;
    font-size: 16px;
    color: #212529;
}

.button_save:focus {
    box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.25);
}

.clear_bin img:hover {
    cursor: pointer;
}

.total_price {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
}

.total_price span {
    display: inline-flex;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    align-items: center;
    text-align: right;
    color: #828282;
}

.nameTextareaBlock{
    display: flex;
}

.nameTextarea {
    width: calc(100% - 20px);
    padding: 5px 0 !important;
    height: 100% !important;
    font-size: 14px;
}

@media screen and (max-width: 767px) {
    .cart {
        width: calc(100% - 40px);

        transform: translate(0, -50%);
        left: 20px;
    }

    .inner {
        padding: 0;
    }

    .left img {
        width: 25px;
    }

    .title {
        font-size: 16px;
    }

    .cross {
        position: absolute;
        right: 10px;
        display: flex;
    }

    .footer {
        flex-wrap: wrap;
        margin-bottom: -10px;
    }

    .info, .button {
        margin-bottom: 10px;
    }
}