.about {
  padding: 16px;
  background-color: #fff;
}

.about_how {
  margin-bottom: 16px;
}

.about_title {
  font-style: normal;
  font-size: 16px;
  color: #333;
  margin-bottom: 16px;
  font-weight: 600;
}

.about_contacts_block:not(:last-child) {
  margin-bottom: 10px;
}

.about_contacts_block a:hover {
  color: #000;
}

.right_block_item {
  display: flex;
}

.right_block_item:not(:last-child) {
  margin-bottom: 10px;
}

.item_text {
  color: #333;
  font-weight: 400;
}

.item_num {
  font-weight: 600;
  color: #81c9ae;
  margin-right: 10px;
}

.item_num, .item_text {
  font-style: normal;
  font-size: 14px;
}
