.goodsList {
  grid-template-columns: repeat(3, 224px);
  gap: 24px;

  display: grid;
}

.good {}

.goodImage {
  margin-bottom: 10px;

  width: 100%;
  height: 224px;
}

.carouselArrowPrev {
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translate(0, -50%);
  color: #d0e8fa;
}

.carouselArrowNext {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translate(0, -50%);
  color: #d0e8fa;
}

.goodImage {
  margin-bottom: 10px;
  
  object-fit: contain;
}

.goodImageTemplate {
  margin-bottom: 10px;
  width: 100%;
  height: 224px;

  background-color: #D9D9D9;
}

.goodName {
  overflow: hidden;

  display: -webkit-box;

  margin-bottom: 4px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #050505;
  
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.goodCode {
  display: block;

  margin-bottom: 16px;
  
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #65676B;
}

.addGoodToOrderButton {
  display: inline-block;

  border: 1px solid #E1DAF5;
  border-radius: 5px;
  padding: 5px 8px;

  background: #E1DAF5;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #1B1A98;
}

.addGoodToOrderButton:hover {
  border: 1px solid #E1DAF5;
  border-radius: 5px;

  background-color: white;

  color: #1B1A98;
}

.addGoodToOrderButton:visited {
  color: #1B1A98;
}

@media screen and (max-width: 992px) {
  .goodsList {
    grid-template-columns: repeat(auto-fill, 224px);
  }
}