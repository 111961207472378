.modalBody {
  padding: 49px 70px 39px;
}

.modalTitle {
  margin-bottom: 38px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  color: #000000;
}

.modalControls {
  display: flex;
}

.modalControl.modalControl {
  display: block;

  border: 1px solid #E1DAF5;
  border-radius: 5px;
  padding: 16px 20px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #1B1A98;
}

.goToOrderLink {
  background: #E1DAF5;
}

.modalControl:not(:last-child) {
  margin-right: 20px;
}