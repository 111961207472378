.modal {
    position: fixed;
    z-index: 101;
    top: 4%;
    left: 2%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 5px;
    max-width: 500px;
    width: 500px;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease;
    max-height: 92vh;
    overflow: auto;
}

.ant-modal {
    top: 2px;
}

.modal.active {
    opacity: 1;
    top: 50%;
    visibility: visible;
    max-width: 95%;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    padding: 1rem 1rem;
}

.title_text {
    font-weight: 500;
    color: #212529;
    font-size: 1.5rem;
    line-height: 1.5;
}

.title_exit {
    cursor: pointer;
    color: #7e7e7e;
    font-size: 1.7rem;
    font-weight: 700;
}

.title_exit:hover {
    color: #000;
}

/* input */
.input_wrap {
    margin-bottom: 10px;
    position: relative;
}

.input_wrap input[name="pass"] {
    padding-right: 45px;
}

.eye {
    position: absolute;
    top: 181px;
    right: 96px;
    opacity: 0.5;
    transition: opacity 0.2s ease;
    cursor: pointer;
    font-size: 25px;
}

.eye.active {
    opacity: 1;
}

.input_password input {
    max-height: 28px !important;
}

.eye img {
    width: 25px;
}

.input_title {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #505050 !important;
}

.loginModal {
    height: calc(1.5em + .75rem + 2px) !important;
}

.input_item {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;
    color: #495057;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input_item:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    background-color: #fff;
}

/* footer */
.footer {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
    padding-top: 2rem;
}

.paddingReset {
    padding-top: 0!important;
}

.link {
    color: #5b55b0;
    cursor: pointer;
}

.button {
    min-width: 120px;
    background-color: #494580;
    border-color: #494580;
    color: #fff;
    height: 35px;
    border-radius: 0.3em;
    font-size: 16px;
    transition: background-color 0.4s ease;
}

.button:hover {
    background-color: #5b55b0;
    border-color: #5b55b0;
    color: #fff;
}

.title_exit {
    cursor: pointer;
    padding: 10px;
}

.title_exit img {
    width: 10px;
    height: 10px;
    opacity: .5;

    transition: opacity .4s ease;
}

.title_exit:hover img {
    opacity: 1;
}

.externalAuthButtons {
    margin-bottom: 30px;
}

