.buttonContentHidden {
  visibility: hidden;
}

.buttonLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: block;
  
  width: 20px;
  height: 20px;
}

.table {
  margin-bottom: 32px;
}

.table :global(.ant-table-cell) {
  padding: 4px 1em !important;
}

.tableRow {

}

.tableRowNotMatched :global(.ant-table-cell:first-child),
.tableRowNotMatched:hover :global(.ant-table-cell:first-child) {
  background-color: #FAD7D7;
}

.tableFormItemContainer {
  align-items: center;
  gap: 10px;

  display: flex;
}

.tableFormItem {
  width: 100%;
}

.tableFormItem :global(.ant-form-item-control-input) {
  min-height: auto;
}

.tableFormItem :global(.ant-input.ant-input),
.tableFormItem :global(.ant-input-number-input) {
  padding: 5px;
  height: auto !important;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

.tableFormItemInput {
  border: 1px solid #d9d9d9;
  width: auto;
  padding: 5px;
  height: auto;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}


.form {
  display: flex;

  margin-bottom: 16px;
}

.inputGroups {
  width: 100%;
}

.inputGroupItem {
  display: flex;
  flex-wrap: wrap;
}

.inputGroupItem:not(:last-child) {
  margin-bottom: 20px;
}

.inputGroupFlex {
  width: calc(50% - 10px);
}

.inputGroupFlex:not(:last-child) {
  margin-right: 20px;
}

.formTitle {
  margin-bottom: 24px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000; 
}

.inputDescription {
  display: block;

  margin-bottom: 9px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.formItem {
  width: 100%;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.formItem :global(.ant-select-selector.ant-select-selector),
.formItem :global(.ant-input.ant-input),
.formItem :global(.ant-input-number.ant-input-number) {
  display: flex;
  align-items: center;

  height: 50px !important;
  padding-left: 20px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.formItem :global(.ant-input-number.ant-input-number) {
  width: 100%;
}

.formItem :global(.ant-input-number-input) {
  padding-left: 0;
}

.formItem :global(.ant-input.ant-input)::placeholder,
.formItem :global(.ant-input-number.ant-input-number)::placeholder {
  color: #757575;
}

.formItem :global(.ant-input-number-input-wrap) {
  width: 100%;
}