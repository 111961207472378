.page {
  font-size: large;
  line-height: 26px;
  padding-top: 60px;
  color: #212529;
  padding-left: 1em;
  padding-right: 1em;
}

.title {
  font-size: 48px;
  font-weight: 900;
  margin-bottom: 2em;
  line-height: 1;
}

.subtitle {
  font-size: 24px;

  margin-bottom: 24px;
}

.block:not(:last-child) {
  margin-bottom: 48px;
}

.list {
  margin-left: 16px;
}

.listItem {
  list-style: disc;
}

.listItem:not(:last-child) {
  margin-bottom: 16px;
}

@media (max-width: 992px) {
  .title {
    font-size: 36px;
  }

  .page {
      padding-top: 80px;
      padding-left: 21px;
      padding-right: 21px;
  }
}
