.page {
}

.title {
    font-size: 48px;
    font-weight: 900;
    padding-bottom: 50px;
    margin-top: 40px;
    line-height: .7;
}

.subtitle {
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 18px;
}

.text {
    margin-bottom: 1rem;
    font-size: large;
    text-align: justify;
    line-height: 1.5;
}

.text.bold {
    font-weight: 700;
}

.section {
    margin-bottom: 40px;
}