.submit_button_container {
  display: flex;
  align-items: center;
}

.transparent_button {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #5B55B0;
}

.transparent_button:hover {
  color: #746cff;
}

@media (max-width: 992px) {
  .transparent_button {
    display: flex;
    justify-content: center;
    border-radius: 8px;
  }

  .submit_button_container {
    display: flex;
    justify-content: center;
  }
}

.centered {
  text-align: center;
}

.buttonContent {
  color: #fff;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}

.buttonContentHidden {
  visibility: hidden;
}

.buttonLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: block;
  
  width: 20px;
  height: 20px;
}