.success_order_container {
    width: 500px;
    background-color: #efefef;
    margin: 0 auto;
    padding: 1em 2em;
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
}

.success_order_title {
    text-align: center;
    font-size: 1.5em;
    font-weight: 600;
    padding-bottom: 1em;
}

.success_order_info span {
    font-size: 1.5em;
    font-weight: 600;
    color: #000;
}

.success_order_info div {
    padding-bottom: 1em;
}

@media (max-width: 992px) {
    .success_order_container {
        min-width: 300px;
        max-width: 100%;
        margin-top: 6em;
    }
}