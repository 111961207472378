.page {
  padding-top: 16px;
  padding-bottom: 48px;
}

.pageBody {
  width: 80%;
  margin: 0 auto;
}

.links {
  align-items: center;
  flex-wrap: wrap;

  display: flex;

  margin-bottom: 34px;
}

.link {
  margin-bottom: 16px;
}

.link:not(:last-child) {
  display: block;

  margin-right: 20px;
}

.pageHeader {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  display: flex;

  margin-bottom: 52px;
}

.pageTitle {
  margin-bottom: 0;
  margin-right: 16px;
  
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 59px;
  color: #262626;
}

.goToMarketLink {
  align-items: center;

  display: flex;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5B55B0;
}

.goToMarketLink:visited {
  color: #5B55B0;
}

.goToMarketLinkIcon {
  margin-left: 4px;
}

.addGoodLink {
  align-items: center;

  display: inline-flex;

  margin-bottom: 40px;
  border: none;
  border-radius: 15px;
  padding: 6px 18px;

  background: #494580;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
}

.addGoodLink:visited {
  color: #FFFFFF;
}

.addGoodLink:hover {
  background: #494580;
  color: #FFFFFF;
}

.addGoodLinkIcon {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-left: 6px;
}

@media screen and (max-width: 992px) {
  .page {
    padding-top: 76px;
  }
}