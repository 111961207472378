.header {
  align-items: center;
  justify-content: space-between;

  display: flex;

  margin-bottom: 12px;
}

.title {
  align-items: center;
  justify-content: space-between;

  display: flex;

  width: 100%;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #050505;

  cursor: pointer;
}

.icon {
  display: flex;
  align-items: center;

  margin-left: 8px;
}

.iconRotated {
  transform: rotate(-180deg);
}
