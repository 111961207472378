.inputGroupItem {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.inputGroupItem input, .inputGroupItem select {
  height: 38px !important;
}

.inputGroupItem :global(.ant-checkbox-group) {
  display: flex;
  flex-direction: column;
}

textArea {
  max-height: 300px;
  min-height: 110px !important;
}

.inputDescription {
  width: 100%;
  padding: 7px 0;
  font-size: 12px;
  font-weight: 700;
  color: #505050;
}

.formItem {
  width: 100%;
}

.form:not(:last-child) {
  margin-bottom: 48px;
}

@media (max-width: 992px) {
  .inputGroupItem input, .inputGroupItem select {
    position: initial!important;
  }

  .inputDescription {
    padding: 4px 0;
  }

  .inputGroupItem > .inputDescription > .ant-form-item-control > div{
    position: initial!important;
  }
}