.pageContentLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.empty {
  border-radius: 8px;
  padding: 20px 0;

  background: #F5FAFE;

  text-align: center;
}

.emptyText {
  margin: 0;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.searchInputContainer {
  margin-bottom: 20px;
}

.searchInput {
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  padding: 0px 18px;
  width: 100%;

  background: #FFFFFF;
}

.tableContainer {
  margin-bottom: 20px;
}

.table {}

.table :global(.ant-table-thead .ant-table-column-title),
.table :global(.ant-table-thead .ant-table-cell) {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  text-transform: uppercase;
}

.table :global(.ant-table-thead .ant-table-cell) {
  padding-bottom: 6px !important;
  padding-left: 10px !important;
  border-bottom: 1px solid #C7E6FF;
  height: auto;
}

.table :global(.ant-table-thead .ant-table-cell .ant-table-column-sorters) {
  justify-content: flex-start;
}

.table :global(.ant-table-thead .ant-table-cell .ant-table-column-title) {
  margin-right: 5px;
  flex: 0;
}

.table :global(.ant-table-tbody .ant-table-cell) {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 10px !important;
  padding-right: 0 !important;
  border-bottom: 1px solid #C7E6FF !important;
}

.tableCell {
  
}

.tableCellName {
  display: block;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
}

.tableCellText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.button {
  position: relative;

  border-radius: 8px;
  padding: 9px 6px;
  
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #1B1A98;
}

.button:hover {
  color: #1B1A98;
}

.button:not(:last-child) {
  margin-right: 10px;
}

.button:disabled {
  cursor: default;
}

.buttonEdit {
  background: #E1DAF5;
}

.buttonDelete {
  background: #F4CFCE;
}

.buttonContent {
  
}

.buttonContentHidden {
  visibility: hidden;
}

.buttonLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: block;
  
  width: 20px;
  height: 20px;
}