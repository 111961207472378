.breadcrumbsContainer {
  margin-bottom: 20px;
}

.good {}

.goodCode {
  display: block;

  margin-bottom: 25px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #65676B;
}

.goodData {
  flex-wrap: wrap;
  gap: 20px;

  display: flex;
}

.goodImages {
  flex-shrink: 0;

  width: 400px;
}

.goodImages :global(.slick-list) {
  margin-bottom: 12px;
}

.goodImages :global(.slick-track) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

.goodImages :global(.slick-slide div) {
  height: 100%;
}

.goodImages :global(.slick-slide) {
  height: auto;
}

.goodImages :global(.slick-slide img) {
  border: 5px solid #fff;
  height: 100%;
  object-fit: contain;
}

.goodImages :global(.slick-dots) {
  position: static;

  display: flex !important;

  justify-content: center;
  align-items: center;
}

.goodImages :global(.slick-thumb li) {
  width: calc((100% - 40px) / 4);
  height: auto;
  border: 1.5px solid #f9f9f9;
  border-radius: 3px;
  padding: 5px;
}

.goodImages :global(.slick-thumb li.slick-active) {
  border: 1.5px solid #74bee9;
}

.goodImages :global(.slick-thumb img) {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}

.goodImage {
  cursor: pointer;
}

.goodImageTemplate {
  flex-shrink: 0;
  
  width: 350px;
  height: 350px;

  background: #D9D9D9;
}

.goodMetric {
  margin-bottom: 20px;
}

.goodMetricTitle {
  display: block;

  margin-bottom: 9px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.goodMetricCounter {
  width: 146px;
}

.goodType {
  width: 146px;
}

.goodControls {
  display: flex;

  margin-bottom: 20px;
}

.goodControl.goodControl {
  display: block;

  border-radius: 5px;
  border: 1px solid #E1DAF5;
  padding: 13px 16px;
  height: auto;
  box-shadow: none;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #1B1A98;
}

.goodControl:hover {
  border-color: #E1DAF5;
  color: #1B1A98;
}

.goodControl:not(:last-child) {
  margin-right: 20px;
}

.addGoodButton,
.addGoodButton:focus,
.addGoodButton:hover {
  background: #E1DAF5;
}

.goToOrderLink,
.goToOrderLink:focus,
.goToOrderLink:hover {
  background: #ffffff;
}

.goodDescriptionTitle {
  margin-bottom: 20px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}

.goodDescription {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #050505;
}

@media screen and (max-width: 640px) {
  .goodImages {
    flex-shrink: 0;
  
    max-width: 100%;
  }
}