.branches {
  width: 80%;
  margin: 0 auto;
  padding: 17px 0;
  font-family: Montserrat, sans-serif;
}

.links {
  align-items: center;
  justify-content: space-between;

  display: flex;

  margin-bottom: 50px;
}

.header {
  justify-content: space-between;
  align-items: center;

  display: flex;

  margin-bottom: 39px;
}

.title {
  margin-bottom: 0;
  
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 59px;
  color: #262626;
}

.addBranch {
  align-items: center;

  display: inline-flex;

  margin-bottom: 60px;
  border: none;
  border-radius: 15px;
  padding: 6px 18px;

  background: #494580;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
}

.addBranch:visited {
  color: #FFFFFF;
}

.addBranch:hover {
  background: #494580;
  color: #FFFFFF;
}

.addBranchIcon {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-left: 10px;
}

@media (max-width: 992px) {
  .title {
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 1.5em;
  }
}