.popUps {
    width: 518px;
    position: fixed;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2000;
}

.alert {
    width: 100%;
    margin-bottom: 5px;
}

@media (max-width: 900px) {
    .popUps {
        width: 75%;
    }
}

@media (max-width: 500px) {
    .popUps {
        width: 85%;
    }
}
