.page {
  padding-top: 16px;
  padding-bottom: 48px;
  padding: 16px 20px 48px;
}

.pageBody {}

.pageHeader {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  display: flex;

  margin-bottom: 52px;
}

.pageTitle {
  margin-bottom: 0;
  margin-right: 16px;
  
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 59px;
  color: #262626;
}

@media screen and (max-width: 992px) {
  .page {
    padding-top: 76px;
  }

  .pageTitle {
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 1.5em;
  }
}