.top_container {
  width: 100%;
}

.form_header {
  display: flex;
  justify-content: space-between;

  margin-bottom: 14px;
}

.input_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  line-height: 1.5;
}

.input_item select {
  background: white;
}

.input_item input,
.input_item select,
.input_item textarea {
  padding: 5px 10px;
  min-width: 326px;
  max-width: 326px;
  border: 1px solid #7b7b7b;
  font-size: 17px;
}

.input_item input {
  height: 34px !important;
}

.input_item select {
  height: 30px !important;
}

.input_item select {
  padding: 5px 10px;
  font-size: 14px;
}

.input_item textarea {
  padding: 5px 10px;
  font-size: 15px;
  height: 100px;
}

.input_item div {
  color: #000;
  font-size: 13px;
}

.input_item_title {
  margin-right: 16px;
  width: 30%;
}

.input_item input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.input_item input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}

.upload_container > span > div:nth-child(2) > div > .ant-tooltip-open {
  background: red !important;
}

.select {
  width: 326px;
  height: auto !important;
}

.uniq_providers > div:nth-child(2) > div:nth-child(1) {
  height: 100% !important;
}

.form_title {
  display: flex;

  font-size: 14px;
  color: #333;
  font-weight: 700;
}

.form_title_tooltip {
  margin-left: 8px;
}

.input_item_providers {
  flex-wrap: wrap;
  gap: 10px 10px;

  display: flex;

  width: 326px;
}

.input_item_providers input {
  width: auto;
  height: auto;
}

@media (max-width: 1279px) {
  .top_container {
    margin-right: 0;
    width: 100%;
    max-height: 100%;
  }

  .input_item_title {
    width: auto;
    margin-right: 0;
  }

  .input_item input,
  .input_item select,
  .input_item textarea {
    padding: 5px 10px;
    border: 1px solid #7b7b7b;
    font-size: 17px;
    height: 34px;
    width: 100%;
    min-width: auto;
    max-width: none;
  }

  .input_item {
    flex-wrap: wrap;
  }

  .input_item > input, .input_item > textarea, .input_bottom > input {
    position: initial !important;
  }

  .input_item div {
    padding-right: 0;
    padding-bottom: 4px;
  }

  .upload_container {
    width: 100%;
  }

  .select {
    width: 100%;
  }
}