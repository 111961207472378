.page {
  padding-top: 24px;
  padding-bottom: 48px;
}

.pageBody {
  display: flex;
}

.pageFilter {
  flex-shrink: 0;

  margin-right: 35px;

  width: 200px;
}

.pageContent {
  position: relative;

  width: 100%;
}

.pageContentTitle {
  margin-bottom: 20px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  word-break: break-word;
}

.pageContentDescription {
  margin-bottom: 25px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #050505;
}

.pageContentLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 992px) {
  .page {
    padding-top: 84px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .pageFilter {
    display: none;
  }
}