.filters {
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: 16px;
}

.filter {}

.filter:not(:last-child) {
  margin-bottom: 8px;
}

.filterLink {
  align-items: center;

  display: flex;

  padding: 8px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  color: #050505;
}

.filterLink::before {
  content: '?';

  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  display: flex;

  margin-right: 8px;
  border-radius: 50%;
  width: 28px;
  height: 28px;

  background: #E4E6EA;

  font-size: 20px;
}

.filterLinkActive {
  background: #F2F2F2;
  border-radius: 4px;
}

.filterLinkActive::before {
  background: #6863B5;

  color: #FFFFFF;
}

.filterLink:hover {
  background: #F2F2F2;
  border-radius: 4px;
}

.newOrderLink {
  display: block;

  margin-bottom: 8px;
  border-radius: 5px;
  padding: 8px; 
  
  background: #E1DAF5;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;

  color: #1B1A98;
}

.newOrderLink:visited {
  color: #1B1A98;
}

.newOrderLink:hover {
  background: #F2F2F2;
}

.divider {
  margin: 0;
  margin-bottom: 16px;
}

.pageFilterTitle {
  display: block;

  margin-bottom: 13px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #050505;
}

.pageFilterList {
  margin-left: -8px;
  margin-right: -8px;
}

.pageFilterRadios {  
  width: 100%;
}

.pageFilterRadio {
  display: flex;

  margin-right: 0;
  padding: 12px 5px 12px 16px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #050505;
}

.pageFilterRadio:hover {
  background: #F2F2F2;
  border-radius: 4px;
}