.image {}

.imageSource {
  border-radius: 3px;
  width: 198px;
  height: 198px;
}

.imageContent {
  display: block;

  width: 100%;
  height: 100%;

  object-fit: contain;
}