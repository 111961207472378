.categories {}

.category {}

.category:not(:last-child) {
  border-bottom: 1px solid #CED0D4;
  padding-bottom: 52px;
  margin-bottom: 27px;
}

.categoryHeader {
  align-items: center;
  justify-content: space-between;
  
  display: flex;

  margin-bottom: 29px;
}

.categoryTitle {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}

.categoryLink {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #5B55B0;
}

.categoryLink:visited {
  color: #5B55B0;
}

.categoryLink:hover {
  text-decoration-line: underline;
}