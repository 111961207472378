.links {
  gap: 0 8px;
  align-items: stretch;

  display: flex;
}

.link {
  display: flex;

  width: 50%;
}

.googleLink {
  gap: 0 12px;
  align-items: center;

  padding: 9px 28px;

  background: #FFFFFF;
  border: 1px solid #4F86EC;
  border-radius: 10px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;

  color: #757575;
}

.googleLink:hover,
.googleLink:active,
.googleLink:visited {
  color: #757575;
}