.modal {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 101;
    padding: 20px;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    transition: .4s ease;
}

.modal.active {
    top: 50%;
    opacity: 1;
    visibility: visible;
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
    max-width: 500px;
    line-height: 28px;
}

.button {
    cursor: pointer;
    display: inline-flex;
    background-color: #494580;
    border-color: #494580;
    color: #fff;
    padding: 8px;
    justify-content: center;
    border-radius: .3em;
    font-size: 16px;
    transition: background-color .4s ease;
    margin-bottom: 10px;
}

.button:last-child {
    margin-bottom: 0;
}

.button.disabled {
    background-color: #c7c3c3;
    cursor: default;
}

.seconds {
    margin-left: 15px;
}
