.form {
  display: flex;

  margin-bottom: 16px;
}

.inputGroups {
  width: 100%;
}

.inputGroupItem {
  display: flex;
  flex-wrap: wrap;
}

.inputGroupItem:not(:last-child) {
  margin-bottom: 20px;
}

.inputGroupItem :global(.ant-checkbox-group) {
  display: flex;
  flex-direction: column;
}

.inputDescription {
  display: block;

  margin-bottom: 9px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.formItem {
  width: 100%;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

