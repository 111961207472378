.empty {
  border-radius: 8px;
  padding: 20px 0;

  background: #F5FAFE;

  text-align: center;
}

.emptyText {
  margin: 0;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 11px;
}

.tableRow {
  background: #F5FAFE;
}

.tableRowHead {
  background: transparent;
}

.tableCell {
  padding: 20px 10px !important;
  width: 50%;

  text-align: left;
}

.tableCell:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tableCell:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tableCellHead {
  border-radius: 0;
}

.tableCellText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.tableCellHeadText {
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}

.button {
  position: relative;

  border-radius: 8px;
  padding: 9px 6px;
  
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #1B1A98;
}

.button:hover {
  color: #1B1A98;
}

.button:not(:last-child) {
  margin-right: 15px;
}

button:disabled {
  cursor: default;
}

.buttonEdit {
  background: #E1DAF5;
}

.buttonDelete {
  background: #F4CFCE;
}

.buttonContent {
  
}

.buttonContentHidden {
  visibility: hidden;
}

.buttonLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: block;
  
  width: 20px;
  height: 20px;
}