.searchForm {
  position: relative;
  
  width: 100%;
}
.searchForm  .searchInput {
  border-color: #f2eeee;
}

.searchInputContainer {
  position: relative;

  align-items: center;

  display: flex;
}

.searchInput {
  border: 1px solid #DBDBDB;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0px 18px;
  width: 100%;

  background: #FFFFFF;
}

.searchInputWithHints {
  border-bottom: 0;
  border-radius: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.searchInput :global(.ant-input-prefix) {
  margin-right: 18px;
}

.searchInput :global(svg) {
  width: 18px;
  height: 18px;
}

.searchInputWithHints.searchInputWithHints:hover {
  border-color: #DBDBDB;
}

.searchInputLoader {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate(0, -50%);
  z-index: 1;
}

.searchHintsContainer {
  position: absolute;
  top: 100%;
  z-index: 1;

  border: 1px solid #DBDBDB;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 0;
  width: 100%;

  background: #FFFFFF;
}

.searchHintsList {
  margin: 0;
}

.searchHint {
  align-items: center;
  
  display: flex;

  padding: 10px 18px;

  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: #1A1A1B;

  cursor: pointer;
}

.searchHint:hover {
  background: #F2F2F2;
}

.searchHint:not(:last-child) {
  margin-bottom: 4px;
}

.searchHintIcon {
  margin-right: 18px;
}

.searchHintIcon :global(svg) {
  width: 18px;
  height: 18px;
}

.searchHintDeleteButton {
  margin-left: auto;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  color: #5B55B0;
}