.loginFooter {
  margin-bottom: 30px;
}

.loginButton {
  width: 100%;
}

.loginLink {
  margin-left: 8px;
}