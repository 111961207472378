.breadcrumbs {
  align-items: center;

  display: flex;
}

.breadcrumbs.breadcrumbs > span {
  align-items: center;
  
  display: flex;

  color: #65676B;
}

.breadcrumb {
  display: block;

  max-width: 200px;

  text-overflow: ellipsis;
  white-space: nowrap;

  overflow: hidden;
}

.breadcrumbLink.breadcrumbLink.breadcrumbLink {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #65676B;
}

.breadcrumbLink.breadcrumbLink:hover {
  color: #65676B;
  text-decoration: underline;
}